<template>
  <section
    class="section-container"
  >
    <v-row
      class="signin"
    >
      <v-col
        cols="8"
        class="float-left"
      >
        <h1><img src="Logo_GROUPE_ADINFO_Vertical_583x600.png"></h1>
      </v-col>
      <v-col
        cols="4"
        class="float-right"
      >
        <h2>{{ $t('login.TITLE') }}</h2>
        <validation-observer ref="observer">
          <v-form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="E-mail"
              rules="required|email"
            >
              <v-text-field
                v-model="email"
                :error-messages="errors"
                :label="$t('login.EMAIL')"
                required
                outlined
                dark
                filled
                dense
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Mot de passe"
              rules="required"
            >
              <v-text-field
                v-model="password"
                :error-messages="errors"
                :label="$t('login.PASSWORD')"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              />
            </validation-provider>
            <div class="text-center">
              <v-btn
                class="signin-btn"
                type="submit"
                rounded
                color="white"
                dark
              >
                {{ $t('login.LOGIN') }}
              </v-btn>
            </div>
            <v-col class="text-right">
              <v-btn
                :to="{ name: 'forgotPassword' }"
                small
                text
                class="btnForgotPassword"
              >
                {{ $t('login.FORGOT_PASSWORD') }}
              </v-btn>
            </v-col>
          </v-form>
        </validation-observer>
        <p>{{ $t('login.DESCRIPTION') }}</p>
        <br>
        <br>
        <br>
        <v-col>
          <v-btn
            class="openaccount-btn"
            href="https://forms.office.com/e/w458LixEjF"
            target="_blank"
            rounded
            color="white"
            dark
          >Je demande mon accès</v-btn>
        </v-col>
      </v-col>
    </v-row>
    <ErrorMessage />
  </section>
</template>

<script>
  import router from '@/router'
  import { mapActions } from 'vuex'
  import { required, email } from 'vee-validate/dist/rules'
  import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
  import '@/sass/login.scss'

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: 'Ne peut pas être vide',
  })

  extend('email', {
    ...email,
    message: 'Email doit être valide',
  })

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('login.TITLE')} - %s`,
      }
    },
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        email: '',
        password: null,
        showPass: false,
      }
    },
    computed: {
      params () {
        return {
          email: this.email,
          password: this.password,
        }
      },
    },
    created () {
      if (this.$store.state.auth.isTokenSet) {
        router.push({ name: 'Tableau de bord' })
      }
    },
    clear () {
      // you can use this method to clear login form
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    },
    methods: {
      ...mapActions(['userLogin']),
      async submit () {
        const valid = await this.$refs.observer.validate()
        if (valid) {
          await this.userLogin({
            email: this.email,
            password: this.password,
          })
        }
      },
    },
  }
</script>
<style>

</style>
